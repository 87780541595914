import CatList from '@components/common/CategoryList'
import { getFilteredCategories } from '@helpers/contentCategoriesHelpers'
import { useMyCountry } from '@hooks/useAuth'
import { useQuery } from '@hooks/useRouter'
import { useHotCategories } from '@hooks/useWhatsHot'
import { arrayToNestedChildren } from '@utils/'
import qs from 'qs'
import { Route, Switch } from 'react-router-dom'
import SEO from './SEO'
import { useFestivalLink } from '@hooks/useFestivalLink'
import './styles.css'

export const CategoryList = ({ categories, additionalQuery }) => {
  const query = useQuery()

  const { locationCountryCode } = useMyCountry()

  const filteredCategories = getFilteredCategories(categories, locationCountryCode)

  const allCategories = (filteredCategories.length > 0
    ? [{ slug: '', id: 0, title: 'Everything', parent: -1 }]
    : []
  )
    .concat(filteredCategories)
    .map(c => ({ ...c, parent: c.parentId || -1, name: c.title }))

  const nest = arrayToNestedChildren(allCategories, -1)

  const renderTree = (data, props, restQuery = {}) => (
    <>
      <CatList
        nestedCategories={data}
        categories={allCategories}
        renderLink={cat =>
          `${props.location.pathname}?${qs.stringify({
            // ...query,
            cat_id: cat.id,
            page: 1,
            category: cat.slug,
            ...(cat.sellerCategory && !props.location.pathname.includes('reviews') && {seller_category: cat.sellerCategory}),
            ...restQuery,
          })}`
        }
        activeCategory={+(query.cat_id || 0)}
        rootCategory={-1}
      />
    </>
  )

  return (
    <div styleName="list">
      <Switch>
        {['/discover/shops'].map((path, index) => (
          <Route
            key={index}
            path={path}
            render={prop =>
              renderTree(nest.map(n => ({ ...n, children: null })), prop, additionalQuery)
            }
          />
        ))}

        <Route
          path={'*'}
          render={prop =>
            renderTree(
              nest.map(n => ({
                ...n,
                children: n.children
                  ? [...n.children.sort((a, b) => a.title.localeCompare(b.title))]
                  : null,
              })),
              prop
            )
          }
        />
      </Switch>
    </div>
  )
}

const Categories = () => {
  const { categories } = useHotCategories()
  const { isHalloween, isChristmas } = useFestivalLink()
  const halloweenCategory = categories.find(c => c.slug === 'costumes')
  const christmasCategory = categories.find(c => c.slug === 'christmas')
  // const restCategories = categories.filter(c => c.slug !== (isHalloween ? 'costumes' : 'christmas'))
  const hotCategories = isHalloween
    ? [{ ...halloweenCategory, parentId: null }, ...categories]
    : isChristmas
      ? [{ ...christmasCategory, parentId: null }, ...categories]
      : categories

  return (
    <>
      <CategoryList categories={hotCategories} />
      <SEO categories={categories} />
    </>
  )
}

CategoryList.defaultProps = {
  categories: [],
  additionalQuery: {},
}

export default Categories
