import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ImageLoading, PureImage, useImageLoading } from '../'
import './styles.css'

interface Props {
  image: string
  link?: string
  title?: string
  onClick?: () => void

  withBorder?: boolean
}

const ImageZoomIn: FC<Props> = ({ image, ...props }) => {
  const { loading } = useImageLoading(image)

  if (loading) {
    return <ImageLoading />
  }

  const Image = <PureImage styleName="inner" image={image} />

  const { link, title, onClick, withBorder } = props
  const Inner = link ? <Link to={link}>{Image}</Link> : Image

  const rest = {
    onClick: typeof onClick == 'function' ? onClick : null,
    ...(title ? { title } : {}),
  }

  return (
    <div className={withBorder ? 'bd-light' : ''} styleName="image" {...rest}>
      {Inner}
    </div>
  )
}

ImageZoomIn.defaultProps = {
  withBorder: false,
}

export default ImageZoomIn
