import ProductShippingPrice from '@components/common/ProductShippingPrice'
import UserAuth from '@components/common/UserAuth'
import ProductUnavailable from '@components/product/v4/ProductDetails/ProductUnavailable'
import ROUTING from '@constants/url'
import { useAddress } from '@hooks/useAddress'
import { Address, Zone } from '@interfaces/address'
import { Platform } from '@interfaces/platform'
import { findZonesByLastZoneId } from '@utils/address'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { DEFAULT_COUNTRY_CODE } from '@constants/'
import { isGreaterChina } from '@utils/i18n'
import { useWarehouseItems } from '@hooks/useWarehouseItems'

const SignIn = ({ onClick }) => (
  <div>
    <a className="bold upper" onClick={onClick}>
      <FormattedMessage id="general.address_sign_in" />
    </a>
  </div>
)

const NoAddress = () => (
  <Link className="bold upper d-bloack" to={ROUTING.ACCOUNT_ADDRESS} target="_blank">
    <FormattedMessage id="product_page.first_address" defaultMessage="Create Your First Address" />
  </Link>
)

const Addr = ({ zones }: { zones: Zone[] }) =>
  zones?.length > 0 ? (
    <span className="text-capitalize">
      {zones
        .map((z, idx) => (
          <span key={z} className={idx === zones.length - 1 ? 'shipping-dest-2nd' : ''}>
            {z}
          </span>
        ))
        .reduce((prev, curr, i) => [
          prev,
          <span className="shipping-dest-2nd" key={i}>
            ,{' '}
          </span>,
          curr,
        ])}
      <span>{':'}</span>
    </span>
  ) : null

const Shipping = props => {
  const { defaultAddress, zones } = useAddress()
  const _isGreatChina = isGreaterChina(defaultAddress?.country || DEFAULT_COUNTRY_CODE)
  const { items, pendingItems } = useWarehouseItems()

  if (!_isGreatChina && (items.length === 0 && pendingItems.length === 0)) {
    return (
      <div className="fs12 text-danger">
        Baopals can no longer ship to the region selected by your <Link to="/account/address" className="blue-link">default address</Link>.
      </div>
    )
  }

  const renderLocation = (addr: Address, zones: Zone[]) => {
    const { price, unable, type } = props
    const hideShippingPrice = unable && !['stock', 'area_supermarket'].includes(unable)

    const renderAddr = addr.country !== DEFAULT_COUNTRY_CODE ? 'China Warehouse:' : <Addr zones={zones.slice(-2).map(z => z.name)} />
    const showGlobalShippingFeesTips = addr.country !== DEFAULT_COUNTRY_CODE
    // const style = {display: showGlobalShippingFeesTips ? 'inline' : 'block'}

    return (
      <>
        {hideShippingPrice ? (
          unable && <ProductUnavailable unable={unable} platform={props.platform} id={props.id} />
        ) : props.platform === Platform.JD ? null : (
          <ProductShippingPrice
            renderLabel={
              <div className="fs12 shipping-location-addr" style={{ display: 'inline' }}>
                <FormattedMessage
                  id="product_page.shipping_location"
                  defaultMessage="Shipping to"
                />{' '}
                {renderAddr}{' '}
              </div>
            }
            price={price}
            showFreeTips={props.showFreeTips}
            showGlobalShippingFeesTips={showGlobalShippingFeesTips}
          />
        )}
      </>
    )
  }

  const MyAddr = () =>
    defaultAddress ? (
      renderLocation(defaultAddress, findZonesByLastZoneId(defaultAddress.zoneId, zones))
    ) : (
      <NoAddress />
    )

  return (
    <div className="shipping-location">
      <UserAuth
        renderBefore={({ openLoginModal }) => <SignIn onClick={openLoginModal} />}
        renderAfter={<MyAddr />}
      />
    </div>
  )
}

Shipping.defaultProps = {
  showFreeTips: false,
  price: 0,
}

Shipping.propTypes = {
  showFreeTips: PropTypes.bool,
  price: PropTypes.number,
}

export default Shipping
