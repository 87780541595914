import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Platform } from '@interfaces/platform'
import { capitalized } from '@utils/'
import ChinaOnly from '@components/common/ChinaOnly'
import { Link } from 'react-router-dom'
import { cloudObjectToURL } from '../../../../../utils'
import { useProductImage } from '../../../../../hooks/useProductImages'

const unavailableReason = [
  'seller_banned',
  'banned',
  'banned_domestic',
  'location',
  'buy_disabled',
  'cart_disabled',
]

const canNotShippingReason = ['area', 'banned_category', 'banned_global']

const Unavailable = ({ unable, platform, id }) => {
  const { selected: selectedImage } = useProductImage(id)
  const reason = unavailableReason.includes(unable) ? 'unavailable' : unable
  const message =
    reason == 'stock' ? 'product_page.out_of_stock' : `unavailability.reason.${reason}`

  if (unable === 'location') {
    return (
      <div className="text-danger">
        Delivery temporarily unavailable due to{' '}
        <a target="_blank" href="https://www.baopals.com/help/cn-your-orders?ref=covid">
          COVID outbreak
        </a>
        .
      </div>
    )
  }

  return (
    <div className="text-danger">
      {canNotShippingReason.includes(unable) ? (
        <>
          This product cannot ship to your{' '}
          <a href="https://www.baopals.com/account/address" target="_blank" className="blue-link">
            address
          </a>
          .
        </>
      ) : (
        <>
          <FormattedMessage
            id={message}
            values={{ prefix: platform === Platform.JD ? 'Temporarily' : '' }}
            defaultMessage='This product is unavailable.'
          >
            {chunks => capitalized(chunks.join('').trim())}
          </FormattedMessage>{' '}
          <Link to={`/search?img=${cloudObjectToURL(selectedImage)}`} className="blue-link bold">Click here</Link> <span style={{ color: '#000' }}><br />to search for alternatives.</span>
        </>
      )}

      {reason == 'unavailable' && (
        <>
          &nbsp;
          <ChinaOnly
            otherwiseRender={
              <Link
                class="blue-link bold"
                to="/help/shopping?ref=products-unavailable"
                target="_blank"
              >
                <FormattedMessage id="unavailability.reason.why" />?
              </Link>
            }
          >
            <Link
              class="blue-link bold"
              to="/help/cn-shopping?ref=unavailable-products"
              target="_blank"
            >
              <FormattedMessage id="unavailability.reason.why" />?
            </Link>
          </ChinaOnly>
        </>
      )}
    </div>
  )
}

export default Unavailable
